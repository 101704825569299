import { FormattedMessage } from 'react-intl'
import useSWR, { SWRResponse } from 'swr'
import { Grid, Link, Typography } from '@mui/material'
import { FileViewRow } from '../../../../ui-component/fileViewRow/FileViewRow'
import Loading from '../../../../ui-component/loading/Loading'
import { CUSTOMER_TYPES, USER_ROLES } from '../../../../constants'
import axiosInstance from '../../../../utils/axiosInstance'
import { useSelector } from '../../../../store/store'
import { hasPermission } from '../../../../utils/tools'
import Orders from '../../../orders/Orders'

export const ReadCustomer = ({ id }) => {
  const { permissions, userInfo } = useSelector((state) => state.user)

  const permission = hasPermission(permissions?.customersDetailsFields)

  const { data, isLoading }: SWRResponse = useSWR(`/customer/${id}`, axiosInstance)

  const customer = data?.data.customer
  if (!customer) return <div>Customer not found</div>

  const { role } = userInfo
  const businessCustomer =
    role !== USER_ROLES.ADMIN.value && role !== USER_ROLES.INTERNAL_USER.value // todo

  const emails = customer.emails ? JSON.parse(customer.emails) : []
  const phones = customer.phoneNumbers ? JSON.parse(customer.phoneNumbers) : []

  if (isLoading) return <Loading />

  return (
    <>
      <div className="customerDetails subFormContainer">
        <div className="header">
          <FormattedMessage id="customer-details" />
        </div>
        <Grid spacing={2} container className="content">
          {permission('name') && customer.name && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="customer-name" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{customer.name}</Typography>
              </Grid>
            </>
          )}
          {permission('type') && (
            <>
              {!businessCustomer && (
                <Grid item xs={3}>
                  <Typography variant="h5">
                    <FormattedMessage id="customer-type" />:
                  </Typography>
                </Grid>
              )}
              {!businessCustomer && (
                <Grid item xs={9}>
                  <Typography variant="body2">
                    {Object.values(CUSTOMER_TYPES).find((i) => i.value === customer.type)?.label}
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {permission('connectedReseller') && customer?.connectedReseller && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">Connected reseller:</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{customer.connectedReseller.name}</Typography>
              </Grid>
            </>
          )}

          {permission('emails') && emails?.length ? (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="emails" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {emails.map((i: string) => (
                  <Typography key={i} variant="body2">
                    {i}
                  </Typography>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <Typography variant="body2" />
              </Grid>
            </>
          )}

          {permission('phoneNumbers') && phones?.length ? (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="phone-numbers" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {phones.map((i: string) => (
                  <Typography key={i} variant="body2">
                    {i}
                  </Typography>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3} />
              <Grid item xs={9}>
                <Typography variant="body2" />
              </Grid>
            </>
          )}

          {permission('automaticTransactionalEmails') && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="auto-transactional-emails" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">
                  {customer.automaticTransactionalEmails ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </>
          )}

          {(role === USER_ROLES.ADMIN.value ||
            (role === USER_ROLES.INTERNAL_USER.value &&
              customer.type === CUSTOMER_TYPES.PARTNER.value)) && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="ad-account-id" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{customer.adAccountId}</Typography>
              </Grid>
            </>
          )}

          {permission('address') && customer.address && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="address" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{customer.address}</Typography>
              </Grid>
            </>
          )}
          {permission('url') && customer.url && (
            <>
              <Grid item xs={3}>
                <Typography variant="h5">
                  <FormattedMessage id="website" />:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Link underline="none" target="_blank" href={customer?.url}>
                  {customer?.url}
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      {permission('logo') && customer?.logo && (
        <div className="customerDetails subFormContainer">
          <div className="header">Logo</div>
          <div className="content">
            <FileViewRow hasPreview fileName="Logo image" fileSource={customer?.logo?.url} />
          </div>
        </div>
      )}
      <div className="customerDetails subFormContainer">
        <div className="header">Orders</div>
        <Orders isOverview={true} customerId={id} customerType={customer.type} />
      </div>
    </>
  )
}
